.setUp .framePage .framePage-body {
  padding: 60px;
}
.setUp .framePage .framePage-body .el-form {
  width: 700px;
  position: relative;
}
.setUp .framePage .framePage-body .el-form .cksl {
  position: absolute;
  top: 0;
  right: -70px;
  color: #4089fa;
  cursor: pointer;
}
.setUp .framePage .framePage-body .el-form .cz {
  text-align: center;
  margin-top: 20%;
}
.setUp .framePage .framePage-body .el-form .cz .el-button {
  padding: 9px 25px;
}
.setUp .framePage .framePage-body .el-form .el-textarea /deep/ .el-textarea__inner {
  height: 170px;
}
.el-popover {
  box-shadow: none!important;
  border: 0!important;
}
.el-popover .el-time-panel {
  box-shadow: none!important;
}
